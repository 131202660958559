import * as React from "react"
import Layout from "../components/layout"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
import FeatherIcon from 'feather-icons-react';
import LogoDiamond from "../components/LogoDiamond"
import { StaticImage } from "gatsby-plugin-image"
import FadeUp from "../components/fadeUp"


const TogetherPage = () => (
  
  <Layout>

    <Helmet>
      <body className="body-together"></body>
    </Helmet>

<section className="grid grid-cols-1 lg:grid-cols-2">

    <div className="h-screen/2 lg:h-screen together">

    </div>

    <div className="flex lg:h-screen items-center justify-center order-last lg:order-first bg-owlwhite p-4 lg:p-8 text-center lg:text-left">
      <motion.div className="md:w-2/3"
        initial={{x: -300, opacity: 0}}
        animate={{x: 0, opacity: 1}}
        transition={{duration: 1}}
      >
        <div className="w-4 my-4 mx-auto lg:mx-3 animate-bounce"><LogoDiamond fill="#E63946"/></div>  
        <h1 className="display-2 w-full text-6xl xl:text-7xl text-owlgreen-dark">Together</h1>
        <motion.hr 
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        />   
        <p className="text-owlgray">Brand identity | Logo design | Website Design &amp; Build | Bespoke image creation</p>
      </motion.div>
    </div>
    
</section>   

<section class="section bg-owlgray text-owlwhite">
    <div class="grid sm:grid-cols-2 gap-4">
      <FadeUp className="flex items-center justify-center">
        <div className="w-2/3">
          <h2 className="display-3 border-b py-2">About Together</h2>
          <p>Unprecedented growth saw Together able to move to the next step as a business, they wanted a new site which made them unique amongst their competitors.</p>
        </div>
      </FadeUp>

      <FadeUp className="flex items-center justify-center">
        <div className="w-2/3">
          <h2 className="display-3 border-b py-2">Our work</h2>
          <p>In a market where images are often stock photos, we created bespoke, exciting imagery which worked with deep colours to give a friendly, modern feel.
          </p>
        </div>
      </FadeUp>
        
    </div>      
</section>


<section class="section bg-togetherblue">
    <div class="container">

        <FadeUp className="w-full md:w-100 md:p-24 p-4 mb-5 flex justify-center">
          <StaticImage
            src="../images/together-logo.svg"
            alt="Together Compliance Logo"
            layout="constrained"
            />          
        </FadeUp>

        <h1 className="display-1 text-center text-owlwhite">A growing SME wanting a new stand-out website.</h1>
        </div>  

        <FadeUp className="flex items-center justify-center">
          <a href="https://www.togethercompliance.co.uk/" className="btn-yellow w-48 py-4 mt-5" target="_blank" rel="noreferrer">
            <div className="display-3">Visit website
            <FeatherIcon icon="external-link" size="24" className="stroke-owlwhite -mt-0.5 ml-3 float-right"/>    
            </div>
          </a>
      </FadeUp>  

</section>

<section class="section section-white">
  <div className="container">
    <div className="flex items-center justify-center">            
        <div className="sm:w-2/3 p-4">
        <StaticImage
          src="../images/together5.png"
          alt="Together Compliance"
          layout="constrained"
        />
        </div>  
    </div>  
  </div>
</section>  
            
   
<section className="section bg-owlgray">
    <div className="container">

    <div class="flex flex-col sm:flex-row">
          <StaticImage
            src="../images/together1.png"
            alt="Together Example 1"
            layout="constrained"
          />
          <StaticImage
            src="../images/together2.png"
            alt="Together Example 2"
            layout="constrained"
          />
          <StaticImage
            src="../images/together3.png"
            alt="Together Example 3"
            layout="constrained"
          />             
        </div>                  
    </div>
</section>       
      
<section class="full-img">        
    <div className="h-96">

    </div>   
</section>  


<section className="section section-testimonial">
    <div className="container">
        <div className="w-full md:w-1/2 mx-auto">
            <div className="text-right">
            <h1 className="text-lg font-thin sm:text-4xl text-center">"The website 2 Owls created was exactly what we wanted - they knew us and our business so well that it perfectly met the brief. Their support after the build, for changes and artwork is excellent, it is great knowing they are there on the end of the phone whenever we need them." </h1>
                <hr />                
                <h1 className="text-lg sm:text-4xl">- Together Compliance Ltd</h1>
            </div>            
        </div>    
    </div>
</section>             



  </Layout>
)

export default TogetherPage
